<script type="module" lang="ts">
export type BadgeVariant =
  | "default"
  | "success"
  | "warning"
  | "error"
  | "primary"
</script>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant?: BadgeVariant
    loading?: boolean
  }>(),
  { variant: "default", loading: false },
)
const badgeClass = computed(() => {
  if (props.variant === "success") {
    return "text-[#075A39] border-[#C7F4D3] bg-[#DDF9E4]"
  }

  if (props.variant === "warning") {
    return "text-[#705500] border-[#FFEBAD] bg-[#FFF3CC]"
  }

  if (props.variant === "error") {
    return "text-[#A52F2E] border-[#ffd4d3] bg-[#ffebeb]"
  }

  if (props.variant === "primary") {
    return "text-[#4711BB] border-[#E8DDFE] bg-[#F5EEFF]"
  }

  return "text-[#183C81] border-[#D6E5FF] bg-[#E5EEFF]"
})
</script>
<template>
  <div
    class="inline-flex items-center gap-x-[5px] rounded-md border px-[5px] py-px pr-1.5 leading-4 tracking-normal text-nowrap [&>svg]:size-3"
    :class="[badgeClass]"
  >
    <svg
      v-if="loading"
      class="animate-spin"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M9.805 8.403a4.5 4.5 0 1 1-7.61-4.806 4.5 4.5 0 0 1 7.61 4.806h0Z"
        stroke="#E8DDFE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M6 1.5a4.5 4.5 0 1 1-3.182 7.682"
        stroke="#9162F9"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
    <div v-if="$slots.icon && !loading" class="*:size-3">
      <slot name="icon" />
    </div>
    <span class="text-xs leading-4 font-medium tracking-normal">
      <slot />
    </span>
  </div>
</template>
